<template>
  <div>
    <div class="card card-info">
      <div class="card-header pt-2 pb-2">
        <a
          data-toggle="collapse"
          href="#permisos"
          aria-expanded="true"
          id="heading-permisos"
          class="d-block"
        >
          <i class="fa fa-chevron-down pull-right"></i>
          Permisos
        </a>
      </div>
      <div id="permisos" class="collapse">
        <div class="card-body">
          <div class="row" v-for="rol in listasForms.roles" :key="rol.rol">
            <div class="form-group">
              <div
                class="custom-control custom-switch custom-switch-off-secundary custom-switch-on-success"
              >
                <input
                  type="checkbox"
                  class="custom-control-input"
                  :id="'rol_' + rol.rol"
                  @change="actualizarRol(rol.rol)"
                  v-model="form.rol[rol.rol]"
                  :checked="rol.estado"
                />
                <label class="custom-control-label" :for="'rol_' + rol.rol">{{
                  rol.rol
                }}</label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "UsuarioPerfil",
  data() {
    return {
      form: {
        rol: [],
      },
      listasForms: {
        roles: [],
      },
      cargando: false,
    };
  },
  methods: {
    actualizarRol(name) {
      axios
        .put("/api/admin/usuarios/assingOrRevokeRole", {
          user: this.$parent.form.id,
          rol: name,
          accion: this.form.rol[name],
        })
        .then((response) => {
          this.$swal({
            icon: "success",
            title: response.data.msg,
            toast: true,
            position: "bottom-end",
            showConfirmButton: false,
            timer: 4000,
            timerProgressBar: true,
          });
        });
    },
    getRolesUser() {
      let data = [];
      axios
        .get("/api/admin/usuarios/roles?usuario=" + this.$parent.form.id)
        .then((response) => {
          this.listasForms.roles = response.data;

          for (let i = 0; i < this.listasForms.roles.length; i++) {
            const rol = this.listasForms.roles[i];
            this.form.rol[rol.rol] = rol.estado;
          }
        });
    },
  },
  mounted() {
    this.getRolesUser();
  },
};
</script>
